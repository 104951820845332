/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef } from "react";
import Label from "components/Label";
import Autocomplete from "react-google-autocomplete";
import { FaMapMarkerAlt as MapIcon } from "react-icons/fa";

import styles from "./styles.module.scss";

type Props = {
  onChange(place: any): void;
  value: any;
  placeholder?: string;
  id?: string;
  disabled?: boolean;
  initialValue?: string;
};

const GoogleMapsAutocomplete: FC<Props> = ({
  onChange,
  value,
  placeholder,
  id,
  disabled = false,
  initialValue
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.container}>
      <div className={styles.autocompleteWrapper}>
        <Autocomplete
          aria-disabled={disabled}
          ref={inputRef}
          id={id}
          placeholder={placeholder}
          options={{ types: "address", fields: "formatted_address" }}
          className={styles.autocomplete}
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={(place) => {
            onChange(place);
          }}
          onChange={(e) => {
            if (!e.currentTarget.value) {
              onChange(undefined);
            }
          }}
        />
      </div>
      {value?.formatted_address && (
        <div className={styles.detail}>
          <MapIcon className={styles.mapIcon} />
          <Label color="white">{value?.formatted_address}</Label>
        </div>
      )}
    </div>
  );
};

GoogleMapsAutocomplete.defaultProps = {
  placeholder: undefined,
  id: undefined,
  disabled: false,
  initialValue: undefined
};

export default GoogleMapsAutocomplete;
