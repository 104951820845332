import React, { FC, ReactNode } from "react";
import { useCaptureSession } from "hooks/use-session";

import { useRouter } from "next/router";
import styles from "./styles.module.scss";

type Props = {
  children: ReactNode;
};

const AppLayout: FC<Props> = ({ children }) => {
  const { pathname } = useRouter();

  useCaptureSession();

  return (
    <div key={pathname} id="app" className={styles.app}>
      {children}
    </div>
  );
};

export default AppLayout;
