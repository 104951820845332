/* eslint-disable @typescript-eslint/no-explicit-any */
import { Value } from "components/FormConstructor/types";
import { match, __ } from "ts-pattern";
import { isEmpty } from "./array";
import { mapper } from "./mapping";
import { purgeObject } from "./object";

const adrPropertyToField: { [key: string]: string } = {
  "street-address": "address",
  locality: "city",
  region: "state",
  "postal-code": "zip",
  "country-name": "country"
};

export const formatErrors = (errors: { [key: string]: string[] }) => {
  const error = Object.keys(errors)
    .map((field) => errors[field])
    .flat()[0];

  return error;
};

export type HSPayload = { errors: { message: string }[] };

export const formatHSErrors = (payload: { errors: { message: string }[] }) => {
  return payload.errors[0].message.split(". ")[1];
};

export interface Place {
  adr_address: string;
  address_components: {
    types: string[];
    long_name: string;
    short_name: string;
  }[];
  formatted_address: string;
}

export const hasPostalCode =
  (error: string) =>
  (place: Value): string | false => {
    return (
      match(place)
        .with(undefined, () => error)
        .with(
          {
            address_components: [{ types: [__.string], long_name: __.string }]
          },
          (value) => {
            const postalCode =
              value.address_components.find(({ types }) =>
                types.includes("postal_code")
              )?.long_name ?? "";

            return postalCode ? false : error;
          }
        )
        .run() ?? error
    );
  };

export const hasAddressAttirbute =
  (attirbutes: ("zip" | "city" | "state")[], error: string) =>
  (place: Value): string | false => {
    return (
      match(place)
        .with(undefined, () => error)
        .with(
          {
            adr_address: __.string
          },
          (value) => {
            const object = parseAdrAddress(value.adr_address);

            return attirbutes.every((attribute) => !!object[attribute])
              ? false
              : error;
          }
        )
        .run() ?? error
    );
  };

export const isDefined = (value: Value): boolean => value !== undefined;

export const parseAdrAddress = (
  adrAddress: string
): {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
} => {
  const domparser = new DOMParser();
  const parsedAdrAddress = domparser.parseFromString(adrAddress, "text/html");

  const fields: { [key: string]: string | null } = {};

  Object.keys(adrPropertyToField).forEach((property) => {
    const propertyElement =
      parsedAdrAddress.body.getElementsByClassName(property)[0];

    fields[adrPropertyToField[property]] = propertyElement?.textContent;
  });

  return purgeObject(fields);
};

export const getFromPlace = (
  attribute: string,
  place: Place,
  type: "long_name" | "short_name" = "long_name"
) =>
  place.address_components.find(({ types }) => types.includes(attribute))?.[
    type
  ] ?? "";

export const required =
  (error: string) =>
  (value: Value): string | false =>
    (!value || (Array.isArray(value) && isEmpty(value))) &&
    value !== false &&
    value !== 0
      ? error
      : false;

type Env = "development" | "production" | "staging";

const getEmailPattern = (env: Env) =>
  env === "production"
    ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    : /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const validEmail =
  (error: string) =>
  (value: Value): string | false =>
    typeof value === "string" &&
    getEmailPattern((process.env.NEXT_PUBLIC_ENV as Env) || "production").test(
      value
    )
      ? false
      : error;

export const minLength =
  (length: number, error: string) =>
  (value: Value): string | false =>
    typeof value === "string" && value.length >= length ? false : error;

export const maxLength =
  (length: number, error: string) =>
  (value: Value): string | false =>
    typeof value === "string" && value.length > length ? error : false;

export const onlyNumbers = (value: string): string => value.replace(/\D/g, "");

export const onlyLetters = (value: string): string =>
  value.replace(/[^a-zA-Z]/g, "");

export const phoneNumberInput = (value: string): string =>
  value.replace(/[^0-9+()-.]/g, "");

export const validateWebsite =
  (error: string) =>
  (value: Value): string | false =>
    typeof value === "string" &&
    /[w]{0,3}[.]{0,1}[\w\d]+\.[a-zA-Z]{3}/.test(value)
      ? false
      : error;

export const validatePhoneNumber =
  (error: string) =>
  (value: Value): string | false =>
    typeof value === "string" &&
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
      value
    )
      ? false
      : error;

export const nonZeroFloat =
  (error: string) =>
  (value: Value): string | false =>
    typeof value === "string" && parseFloat(value) !== 0 ? false : error;

const stringCompare = (valueA: string, valueB: string): boolean =>
  !valueA.localeCompare(valueB);

const equals = mapper(
  {
    string: stringCompare
  },
  stringCompare
);

export const sameInput =
  (error: string) =>
  (valueA: Value, valueB: Value): string | false =>
    equals(typeof valueA)(valueA, valueB) ? false : error;
