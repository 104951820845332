/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, CSSProperties } from "react";
import Markdown from "react-markdown";
import styled from "styled-components";
import { Themed } from "theme/ThemeCreator";

import { Theme } from "theme/types";

import styles from "./styles.module.scss";
import { getVariantStyles } from "./utils";

type Props = {
  id?: string;
  variant?: string;
  children: string | string[] | number | undefined;
  color?: string;
  ellipsis?: boolean;
  className?: string;
  breakWord?: boolean;
  useMarkdown?: boolean;
  linkColor?: string;
  style?: CSSProperties;
  theme: Theme;
  renderNode?: boolean;
};

export type { Props as LabelProps };

const Label = forwardRef<HTMLDivElement, Props>(
  (
    {
      variant = "body",
      children,
      color = "white",
      ellipsis = false,
      className = "",
      breakWord = true,
      id,
      useMarkdown,
      linkColor = "link",
      style,
      theme,
      renderNode
    },
    ref
  ) => {
    const { colors } = theme.label;

    const {
      Component = "div",
      mobile,
      tablet,
      ...variantStyles
    } = getVariantStyles(theme, variant);

    return useMarkdown && typeof children === "string" ? (
      <Markdown
        components={{
          li: ({ ...props }) => (
            <li
              className={className}
              {...props}
              style={{ color: colors[linkColor], ...style, ...variantStyles }}
            />
          ),
          p: ({ ...props }) => (
            <p
              {...props}
              className={`${className} ${styles.base} ${
                ellipsis && styles.ellipsis
              } ${breakWord && styles.breakWord}`}
              style={{ color: colors[color], ...style, ...variantStyles }}
            />
          ),
          a: ({ ...props }) => (
            <a
              {...props}
              style={{ color: colors[linkColor], textDecoration: "underline" }}
            />
          )
        }}
      >
        {children}
      </Markdown>
    ) : (
      <Component
        ref={ref}
        id={id}
        className={`${className} ${styles.base} ${
          ellipsis && styles.ellipsis
        } ${breakWord && styles.breakWord}`}
        style={{
          color: colors[color],
          ...style,
          ...variantStyles
        }}
      >
        {children === undefined ? "" : renderNode ? children : String(children)}
      </Component>
    );
  }
);

const StyledLabel = styled(Label)<Props>`
  ${({ theme, variant = "body" }) => {
    const variantStyles = getVariantStyles(theme, variant);

    const { tablet, mobile } = variantStyles;

    return `
      @media ${tablet?.media} {
        font-size: ${tablet?.fontSize} !important;
      }

      @media ${mobile?.media} {
        font-size: ${mobile?.fontSize} !important;
      }
    `;
  }}
`;

Label.defaultProps = {
  id: undefined,
  color: "black",
  variant: "body",
  ellipsis: false,
  className: "",
  breakWord: true,
  useMarkdown: false,
  linkColor: "link",
  style: {}
};

export default Themed(StyledLabel);
