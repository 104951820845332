import React, { FC, useEffect } from "react";
import { Radio, RadioGroup } from "@mui/material";
import Label from "components/Label";
import Checked from "../../../public/checked.svg";
import Unchecked from "../../../public/unchecked.svg";

import styles from "./styles.module.scss";

type Props = {
  onChange: (newValue: string) => void;
  initialValue?: string;
  id?: string;
  disabled?: boolean;
  options: { label: string; value: string }[];
  value: string;
  inline?: boolean;
};

const RadioGroupField: FC<Props> = ({
  onChange,
  initialValue,
  options,
  value,
  id,
  disabled,
  inline
}) => {
  useEffect(() => {
    if (initialValue !== undefined) onChange(initialValue);
  }, []);

  return (
    <div className={styles.container}>
      <RadioGroup
        onChange={(event) => {
          onChange(event.currentTarget.value);
        }}
        id={id}
        value={value}
        className={inline ? styles.inline : undefined}
      >
        {options.map(({ label, value: optionValue }) => (
          <div className={styles.radio}>
            <Radio
              checkedIcon={<Checked className={styles.icon} />}
              icon={<Unchecked className={styles.icon} />}
              value={optionValue}
              disabled={disabled}
              checked={optionValue === value}
            />
            <Label color="white">{label}</Label>
          </div>
        ))}
      </RadioGroup>
    </div>
  );
};

RadioGroupField.defaultProps = {
  initialValue: undefined,
  id: undefined,
  disabled: false,
  inline: false
};

export default RadioGroupField;
