import React, { ReactElement, forwardRef, FormEvent } from "react";
import styled from "styled-components";
import { CircularProgress as Loading } from "@mui/material";

import { useTheme } from "theme/ThemeCreator";
import { Theme } from "theme/types";
import Label, { LabelProps } from "components/Label";
import segment from "lib/segment";

import { motion } from "framer-motion";
import styles from "./styles.module.scss";

interface Props {
  type?: "button" | "submit";
  animated?: boolean;
  Icon?: ReactElement;
  variant?: string;
  children?: string;
  size?: string;
  onClick?(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  onSubmit?(e?: FormEvent<HTMLButtonElement>): void;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
  className?: string;
  iconAfter?: boolean;
  stopPropagation?: boolean;
  labelProps?: Omit<LabelProps, "children" | "theme">;
  track?: {
    event: string;
    location: string;
  };
}

type StyledProps = {
  theme: Theme;
  variant: string;
  size: string;
  $iconAfter: boolean;
  $isIconVariant: boolean;
};

const StyledButton = styled(motion.button)<StyledProps>`
  ${({ theme, variant, size, disabled, $iconAfter, $isIconVariant }) => {
    const buttonTheme = theme.button;

    const {
      backgroundColor,
      borderColor,
      borderRadius,
      color,
      hover,
      disabled: disabledTheme
    } = buttonTheme[variant];

    const {
      backgroundColor: hoverBackgroundColor,
      borderColor: hoverBorderColor,
      color: hoverColor
    } = hover;

    const {
      color: disabledColor,
      backgroundColor: disabledBackgroundColor,
      borderColor: disabledBorderColor
    } = disabledTheme;

    return `
      align-items: center;
      display: flex;
      flex-direction: ${$iconAfter ? "row-reverse" : "row"};
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor ?? backgroundColor};
      border-radius: ${borderRadius}px;
      color: ${color};
      ${!disabled && "cursor: pointer;"}
      font-weight: 500;
      justify-content: center;
      padding: ${
        $isIconVariant ? buttonTheme.iconPadding : buttonTheme[`${size}Padding`]
      };
      transition: all 0.3s;

      &:hover {
        background-color: ${hoverBackgroundColor};
        border: 1px solid ${hoverBorderColor ?? hoverBackgroundColor};
        color: ${hoverColor};
      }

      &:disabled {
        background-color: ${disabledBackgroundColor};
        border: 1px solid ${disabledBorderColor ?? disabledBackgroundColor};
        color: ${disabledColor};
      }
    `;
  }}
`;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      Icon,
      children,
      variant = "primary",
      size = "xxlarge",
      onClick = () => {},
      onSubmit = () => {},
      disabled = false,
      loading,
      id,
      className,
      iconAfter = false,
      stopPropagation,
      animated = false,
      labelProps,
      type,
      track
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <StyledButton
        type={type}
        transition={{ duration: 0.3, ease: "easeIn" }}
        whileHover={animated ? { scale: 1.1 } : undefined}
        ref={ref}
        $isIconVariant={!!Icon && !children}
        id={id}
        onClick={(event) => {
          if (stopPropagation) event.stopPropagation();
          if (track) {
            segment.track(track.event, { location: track.location });
          }
          return onClick(event);
        }}
        onSubmit={(e) => onSubmit(e)}
        size={size}
        variant={variant}
        theme={theme}
        disabled={disabled || loading}
        className={className}
        $iconAfter={iconAfter}
      >
        {loading && (
          <Loading className={styles.loading} style={{ marginRight: 10 }} />
        )}
        {Icon}
        {children && (
          <Label variant="button" {...labelProps}>
            {children}
          </Label>
        )}
      </StyledButton>
    );
  }
);

Button.displayName = "Button";

Button.defaultProps = {
  Icon: undefined,
  variant: "primary",
  size: "xxlarge",
  disabled: false,
  loading: false,
  id: undefined,
  className: undefined,
  iconAfter: false,
  children: undefined,
  stopPropagation: false,
  animated: false,
  labelProps: {},
  type: "button",
  onClick: () => {},
  onSubmit: () => {},
  track: undefined
};

export default Button;
