/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Label from "components/Label";
import Image from "next/image";
import React, { FC, useEffect } from "react";
import { isEmpty } from "utils/array";

import styles from "./styles.module.scss";

type Option = {
  value: string;
  label: string;
  icon: string;
};

type Props = {
  onChange: (newValue: string[] | undefined) => void;
  initialValue?: string[];
  value: string[] | undefined;
  options: Option[];
};

const IconSelect: FC<Props> = ({ onChange, initialValue, value, options }) => {
  useEffect(() => {
    if (initialValue) onChange(initialValue);
  }, []);

  return (
    <div className={styles.container}>
      {options.map(({ value: optionValue, label, icon }) => {
        const selected = value?.includes(optionValue);

        return (
          <div
            onClick={() => {
              if (selected) {
                const newValues = value?.filter(
                  (selectedValue) => selectedValue !== optionValue
                );

                if (newValues && isEmpty(newValues)) onChange(undefined);
                else onChange(newValues);
              } else if (value && !isEmpty(value))
                onChange([...value, optionValue]);
              else onChange([optionValue]);
            }}
            className={`${styles.item} ${selected && styles.itemSelected}`}
          >
            <div
              className={`${styles.iconContainer} ${
                selected && styles.selected
              }`}
            >
              <div className={styles.iconWrapper}>
                <Image alt="" layout="fill" objectFit="contain" src={icon} />
              </div>
            </div>
            <Label
              className={styles.label}
              variant="centered-body"
              color="primary2"
            >
              {label}
            </Label>
          </div>
        );
      })}
    </div>
  );
};

IconSelect.defaultProps = {
  initialValue: undefined
};

export default IconSelect;
