const BACKGROUND_COLORS = {
  primaryBackground: "#E1DFD9",
  secondaryBackground: "#FFFFFF"
};

const COMMON_COLORS = {
  white: "#FFF"
};

const colors = {
  transparent: "#00000000",

  black: "#484848",

  primaryVariant1: "#0d291c",
  primaryVariant2: "#E1DFDA",

  lightPrimaryVariant1: "#00412A",

  supplemental: "#0a6154",

  secondaryVariant1: "#2b5f54",
  secondaryVariant2: "#296379",

  supplementalVariant1: "#647f7f",
  supplementalVariant2: "#ffffff",

  accent: "#b06148",

  gray: "#E1DFDA",
  gray75: "#7a7a7a",
  gray50: "#DBDBDB",
  gray25: "#f0f0f0",

  ...BACKGROUND_COLORS,

  ...COMMON_COLORS
};

const space = [0, 2, 4, 8, 16, 32, 64, 128, 256];
const sizes = {
  avatar: 36
};
const radii = {
  default: 5,
  circle: 99999
};
const breakpoints = ["40em", "52em", "64em"];

const media = {
  desktop: "only screen and (min-width : 1200px)",
  tablet: "only screen and (max-width : 1199px) and (min-width : 767px)",
  mobile: "only screen and (max-width : 766px)"
};

const fontSizes = [12, 14, 18, 20, 24, 32, 40, 48, 64];

const fontFamilies = {
  body: "lic_grotesque, sans-serif",
  heading: "lic_sanomat, serif"
};

const fontWeights = {
  light: 300,
  body: 400,
  semibold: 500,
  bold: 600,
  heading: 700
};

const label = {
  leftAligned: {
    textAlig: "left",
    width: "100%"
  },
  centered: {
    textAlign: "center",
    width: "100%",
    margin: "0 auto"
  },
  h: {
    lineHeight: "120%",
    fontFamily: fontFamilies.heading,
    Component: "h1",
    fontWeight: fontWeights.body
  },
  h1: {
    Component: "h1",
    lineHeight: "120%",
    fontFamily: fontFamilies.heading,
    fontSize: "64px",
    fontWeight: fontWeights.body,
    mobile: {
      media: media.mobile,
      fontSize: "32px"
    },
    tablet: {
      media: media.tablet,
      fontSize: "32px"
    }
  },
  h2: {
    Component: "h2",
    lineHeight: "120%",
    fontFamily: fontFamilies.heading,
    fontSize: "48px",
    fontWeight: fontWeights.light,
    mobile: {
      media: media.mobile,
      fontSize: "24px"
    },
    tablet: {
      media: media.tablet,
      fontSize: "30px"
    }
  },
  h3: {
    Component: "h3",
    lineHeight: "120%",
    fontFamily: fontFamilies.heading,
    fontSize: "40px",
    fontWeight: fontWeights.body,
    mobile: {
      media: media.mobile,
      fontSize: "28px"
    },
    tablet: {
      media: media.tablet,
      fontSize: "28px"
    }
  },
  h4: {
    lineHeight: "120%",
    fontFamily: fontFamilies.heading,
    fontSize: "32px",
    fontWeight: fontWeights.body,
    mobile: {
      media: media.mobile,
      fontSize: "20px"
    },
    tablet: {
      media: media.tablet,
      fontSize: "24px"
    }
  },
  h5: {
    lineHeight: "120%",
    fontFamily: fontFamilies.heading,
    fontSize: "24px",
    fontWeight: fontWeights.body,
    mobile: {
      media: media.mobile,
      fontSize: "18px"
    },
    tablet: {
      media: media.tablet,
      fontSize: "22px"
    }
  },
  h6: {
    lineHeight: "120%",
    fontFamily: fontFamilies.heading,
    fontSize: "20px",
    fontWeight: fontWeights.body
  },
  s: {
    fontFamily: fontFamilies.body,
    fontWeight: fontWeights.body
  },
  s1: {
    fontFamily: fontFamilies.body,
    fontSize: "32px",
    fontWeight: fontWeights.body,
    tablet: {
      media: media.tablet,
      fontSize: "20px"
    },
    mobile: {
      media: media.mobile,
      fontSize: "20px"
    }
  },
  s2: {
    fontFamily: fontFamilies.body,
    fontSize: "24px",
    fontWeight: fontWeights.body,
    tablet: {
      media: media.tablet,
      fontSize: "20px"
    },
    mobile: {
      media: media.mobile,
      fontSize: "20px"
    }
  },
  s3: {
    fontFamily: fontFamilies.body,
    fontSize: "20px",
    fontWeight: fontWeights.body,
    tablet: {
      media: media.tablet,
      fontSize: "16px"
    },
    mobile: {
      media: media.mobile,
      fontSize: "16px"
    }
  },
  body: {
    fontFamily: fontFamilies.body,
    fontSize: "16px",
    fontWeight: fontWeights.body
  },
  small: {
    fontFamily: fontFamilies.body,
    fontSize: "12px",
    fontWeight: fontWeights.body
  },
  xsmall: {
    fontFamily: fontFamilies.body,
    fontSize: "10px",
    fontWeight: fontWeights.body
  },
  button: {
    fontFamily: fontFamilies.body,
    fontSize: "12px",
    fontWeight: fontWeights.body,
    textTransform: "uppercase",
    letterSpacing: "0.20em"
  },
  colors: {
    primary: colors.primaryVariant1,
    lightPrimary: colors.lightPrimaryVariant1,
    supplemental: colors.supplementalVariant1,
    primary2: colors.primaryVariant2,
    white: colors.white,
    transparent: colors.transparent,
    gray: colors.gray,
    secondary: colors.secondaryVariant1,
    secondary2: colors.secondaryVariant2,
    accent: colors.accent,
    link: colors.supplemental
  },
  italic: {
    fontStyle: "italic"
  },
  light: {
    fontWeight: fontWeights.light
  },
  bold: {
    fontWeight: fontWeights.bold
  },
  semibold: {
    fontWeight: fontWeights.semibold
  },
  capitalized: {
    textTransform: "uppercase",
    letterSpacing: "0.20em"
  }
};

const button = {
  xxlargePadding: "15px 36px",
  xlargePadding: "10px 35px",
  largePadding: "10px 15px",
  mediumPadding: "10px 30px",
  smallPadding: "5px",
  iconPadding: "5px",
  primary: {
    backgroundColor: colors.primaryVariant1,
    borderRadius: 7,
    color: colors.white,
    hover: {
      backgroundColor: `lighten(${colors.primaryVariant1}, 0.15)`
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray
    }
  },
  positive: {
    backgroundColor: colors.secondaryVariant1,
    borderRadius: 7,
    color: colors.white,
    hover: {
      backgroundColor: `lighten(${colors.secondaryVariant1}, 0.50)`
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray
    }
  },
  accent: {
    backgroundColor: colors.accent,
    borderRadius: 7,
    color: colors.white,
    hover: {
      backgroundColor: colors.accent
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray75
    }
  },
  cta: {
    backgroundColor: colors.accent,
    borderRadius: 7,
    color: colors.white,
    hover: {
      backgroundColor: colors.accent
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray
    }
  },
  text: {
    backgroundColor: colors.transparent,
    borderRadius: 7,
    color: colors.supplemental,
    hover: {
      backgroundColor: colors.transparent
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray
    }
  },
  secondary: {
    backgroundColor: colors.white,
    borderRadius: 7,
    color: colors.primaryVariant1,
    hover: {
      backgroundColor: colors.gray25
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray
    }
  },
  secondary2: {
    backgroundColor: colors.white,
    borderRadius: 7,
    color: colors.supplementalVariant2,
    hover: {
      backgroundColor: colors.gray25
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray
    }
  },
  "secondary-outlined": {
    backgroundColor: colors.white,
    borderRadius: 7,
    borderColor: colors.primaryVariant1,
    color: colors.primaryVariant1,
    hover: {
      borderColor: colors.primaryVariant1,
      backgroundColor: colors.gray25
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray
    }
  },
  tertiary: {
    backgroundColor: colors.lightPrimaryVariant1,
    borderRadius: 7,
    color: colors.white,
    hover: {
      backgroundColor: colors.lightPrimaryVariant1
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray75
    }
  }
};

const theme = {
  button,
  label,
  fontFamilies,
  breakpoints,
  fontSizes,
  colors,
  space,
  sizes,
  radii,
  fontWeights,
  media
} as const;

export default theme;
